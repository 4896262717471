import React, { Fragment, useEffect, useState } from "react";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DetailsProps } from "../DetalhesPagamento";
import { useSiteContext } from "../../hooks/useSiteContext";
import { allPayments } from "../../services/pagSeguroApi";
import { Link } from "react-router-dom";

import "./style.css";

export function ListaPagemento() {
  const [payments, setPayments] = useState<DetailsProps[]>([]);

  const { tokens } = useSiteContext();

  useEffect(() => {
    (async () => {
      await allPayments(tokens).then(async (resp) =>
        setPayments((await resp.data).result)
      );
    })();
  }, [tokens]);

  return (
    <ul className="list-payments">
      <li>
        <span style={{ flexBasis: "20%" }}>Cód.</span>
        <span style={{ flexBasis: "25%" }}>Data Compra</span>
        <span style={{ flexBasis: "45%" }}>Forma Pgto.</span>
        <span style={{ flexBasis: "10%", textAlign: "center" }}>Açoes</span>
      </li>
      {payments.map((rws, i) => (
        <li key={`pay-${i}`}>
          <span style={{ flexBasis: "20%" }}>{rws.cod_venda}</span>
          <span style={{ flexBasis: "25%" }}>{rws.created_at}</span>
          <span style={{ flexBasis: "45%" }}>
            {rws.transacoes[0].forma_pgto}
          </span>
          <span style={{ flexBasis: "10%", textAlign: "center" }}>
            <Link to={`/painel/detalhes/${rws.id}`} className="btn">
              Detalhes <FontAwesomeIcon icon={faCircleInfo} />
            </Link>
          </span>
        </li>
      ))}
    </ul>
  );
}
