import { IClientes, ITokens } from "../global/types";
import { api } from "./api";

export interface IInstallments {
  quantity: number;
  interestFree: boolean;
  installmentAmount: number;
  totalAmount: number;
}

export interface IPaymentPagSeguro extends IInstallments {
  bankId: string;
  cardHolderName: string;
  tokenPagSeguro: string;
  tokenHashPagSeguro: string;
  finalize: boolean;
}

export interface IInstallmentsBrands {
  [key: string]: IInstallments[];
}

export async function finalizeCreditCard(
  paymentPagSeguro: IPaymentPagSeguro,
  cliente: IClientes,
  items: {
    id: string;
    description: string;
    quantity: number;
    amount: number;
    weight: number;
    shippingCost: number;
  }[]
) {
  return await api.post("pagseguro/credit", {
    customer: {
      name: cliente.nome,
      email: cliente.email,
      phone: cliente.telefone,
      document: cliente.cpf,
      birthdate: cliente.data_nascimento,
    },
    shipping: {
      street: cliente.endereco.trim(),
      number: cliente.nr,
      district: cliente.bairro.trim(),
      postalCode: cliente.cep,
      city: cliente.cidade.trim(),
      state: cliente.estado.trim(),
    },
    billing: {
      street: cliente.endereco.trim(),
      number: cliente.nr,
      district: cliente.bairro.trim(),
      postalCode: cliente.cep,
      city: cliente.cidade.trim(),
      state: cliente.estado.trim(),
    },
    items,
    tokenPagSeguro: paymentPagSeguro.tokenPagSeguro,
    tokenHashPagSeguro: paymentPagSeguro.tokenHashPagSeguro,
    cardHolderName: paymentPagSeguro.cardHolderName,
    installments: paymentPagSeguro.quantity,
    installmentAmount: paymentPagSeguro.installmentAmount,
  });
}

export async function finalizeDebitCard(
  paymentPagSeguro: IPaymentPagSeguro,
  cliente: IClientes,
  items: {
    id: string;
    description: string;
    quantity: number;
    amount: number;
    weight: number;
    shippingCost: number;
  }[]
) {
  return await api.post("pagseguro/debit", {
    customer: {
      name: cliente.nome,
      email: cliente.email,
      phone: cliente.telefone,
      document: cliente.cpf,
      birthdate: cliente.data_nascimento,
    },
    shipping: {
      street: cliente.endereco.trim(),
      number: cliente.nr,
      district: cliente.bairro.trim(),
      postalCode: cliente.cep,
      city: cliente.cidade.trim(),
      state: cliente.estado.trim(),
    },
    items,
    tokenPagSeguro: paymentPagSeguro.tokenPagSeguro,
    tokenHashPagSeguro: paymentPagSeguro.tokenHashPagSeguro,
    installments: 1,
  });
}

export async function finalizeBoleto(
  paymentPagSeguro: IPaymentPagSeguro,
  cliente: IClientes,
  items: {
    id: string;
    description: string;
    quantity: number;
    amount: number;
    weight: number;
    shippingCost: number;
  }[]
) {
  return await api.post("pagseguro/boleto", {
    customer: {
      name: cliente.nome,
      email: cliente.email,
      phone: cliente.telefone,
      document: cliente.cpf,
      birthdate: cliente.data_nascimento,
    },
    shipping: {
      street: cliente.endereco.trim(),
      number: cliente.nr,
      district: cliente.bairro.trim(),
      postalCode: cliente.cep,
      city: cliente.cidade.trim(),
      state: cliente.estado.trim(),
    },
    items,
    tokenPagSeguro: paymentPagSeguro.tokenPagSeguro,
    tokenHashPagSeguro: paymentPagSeguro.tokenHashPagSeguro,
    installments: 1,
  });
}

export async function detailsPaymentId({
  idVenda,
  token,
}: {
  idVenda: string;
  token: ITokens;
}) {
  return await api.get(`pagseguro/payment/${idVenda}`, {
    headers: { Authorization: `${token}` },
  });
}

export async function allPayments({ token }: ITokens) {
  return await api.get(`pagseguro/payments`, {
    headers: { Authorization: `${token}` },
  });
}
