import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { IClientes } from "../../global/types";
import { useSiteContext } from "../../hooks/useSiteContext";
import { api } from "../../services/api";
import { convertToUs } from "../../utils/dateProvider";

import "./style.css";

export function CadCliente() {
  const navigate = useNavigate();
  const { saveTokens } = useSiteContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [cadastro, setCadastro] = useState<IClientes>({} as IClientes);
  const [redSenha, setRedSenha] = useState("");

  const handleCadastrar = async () => {
    if (!cadastro.nome || cadastro.nome.length <= 3)
      return alert("Informe seu nome completo");

    if (!cadastro.email || cadastro.email.length <= 7)
      return alert("Informe seu e-mail");

    if (!cadastro.cpf || cadastro.cpf.length < 14)
      return alert("Informe seu CPF");

    if (!cadastro.data_nascimento || cadastro.data_nascimento.length !== 10)
      return alert("Informe sua Data de Nascimento");

    if (!cadastro.endereco || cadastro.endereco.length < 5)
      return alert("Informe seu Endereço");

    if (!cadastro.bairro || cadastro.bairro.length < 5)
      return alert("Informe seu Bairro");

    if (!cadastro.cidade || cadastro.cidade.length < 5)
      return alert("Informe sua Cidade");

    if (!cadastro.estado || cadastro.estado.length < 2)
      return alert("Informe seu Estado");

    if (!cadastro.telefone || cadastro.telefone.length < 14)
      return alert("Informe seu Telefone");

    if (!cadastro.senha || cadastro.senha.length <= 2)
      return alert("Informe sua Senha");

    if (cadastro.senha !== redSenha) return alert("As senhas não coincidem");

    setLoading(true);
    setTxtLoading("Verificando cadastro...");

    try {
      const reqCliente = await api.get(
        `clientes?filter_email=${cadastro.email}`
      );

      if (!reqCliente.data)
        throw new Error("Erro ao acessar informações do servidor");

      const alreadyExists = reqCliente.data as IClientes[];

      if (alreadyExists.length > 0) throw new Error("Cliente já cadastrado");

      setTxtLoading("Salvando cadastro...");

      const reqSave = await api.post("clientes", {
        ...cadastro,
        data_nascimento: convertToUs(cadastro.data_nascimento),
      });

      if (!reqSave.data)
        throw new Error("Erro ao acessar informações do servidor de cadastros");

      setTxtLoading("Efetuando login com sua nova conta...");

      const reqLogin = await api.post("clientes/login", {
        usuario: cadastro.email,
        senha: cadastro.senha,
      });

      if (!reqLogin.data)
        throw new Error("Erro ao acessar informações do login");

      const result = reqLogin.data;
      saveTokens(result.token, result.cliente);
      navigate("/pgto");
    } catch (err: any) {
      if (err.response?.data?.msg) alert(err.response.data.msg);
      else alert("Erro ao salvar cadastro. Tente novamente mais tarde");

      setLoading(false);
      setTxtLoading("");
    }
  };

  return (
    <div id="pgCadCliente">
      {loading && <Load txtLoading={txtLoading} />}

      {!loading && (
        <>
          <div className="painel">
            <h3>Dados do Cliente</h3>

            <div>
              <label>Nome Completo:</label>
              <Input
                mask="none"
                value={cadastro.nome ? cadastro.nome : ""}
                inputMaskChange={(v) => setCadastro((e) => ({ ...e, nome: v }))}
                placeholder="Nome Completo"
              />
            </div>

            <div className="flexRow">
              <div>
                <label>Telefone/Ceular:</label>
                <Input
                  mask="phone"
                  value={cadastro.telefone ? cadastro.telefone : ""}
                  inputMaskChange={(v) =>
                    setCadastro((e) => ({ ...e, telefone: v }))
                  }
                  type="email"
                  placeholder="Telefone/Celular"
                  maxLength={15}
                />
              </div>
              <div>
                <label>E-mail:</label>
                <Input
                  mask="none"
                  value={cadastro.email ? cadastro.email : ""}
                  inputMaskChange={(v) =>
                    setCadastro((e) => ({ ...e, email: v }))
                  }
                  type="email"
                  placeholder="E-mail"
                />
              </div>
            </div>

            <div className="flexRow">
              <div>
                <label>CPF:</label>
                <Input
                  mask="cpf_cnpj"
                  value={cadastro.cpf ? cadastro.cpf : ""}
                  inputMaskChange={(v) =>
                    setCadastro((e) => ({ ...e, cpf: v }))
                  }
                  type="tel"
                  placeholder="CPF"
                  maxLength={18}
                />
              </div>
              <div>
                <label>Data Nascimento:</label>
                <Input
                  mask="date"
                  value={
                    cadastro.data_nascimento ? cadastro.data_nascimento : ""
                  }
                  inputMaskChange={(v) =>
                    setCadastro((e) => ({ ...e, data_nascimento: v }))
                  }
                  type="tel"
                  placeholder="Data de Nascimento (DD/MM/AAAA)"
                  maxLength={10}
                />
              </div>
            </div>
          </div>

          <div className="painel">
            <h3>Endereço</h3>

            <div className="flexRow">
              <div style={{ flexBasis: "15%" }}>
                <label>CEP:</label>
                <Input
                  mask="cep"
                  type="tel"
                  value={cadastro.cep ? cadastro.cep : ""}
                  inputMaskChange={(v) =>
                    setCadastro((e) => ({ ...e, cep: v }))
                  }
                  placeholder="CEP"
                />
              </div>
              <div style={{ flexBasis: "45%" }}>
                <label>Endereço:</label>
                <Input
                  mask="none"
                  value={cadastro.endereco ? cadastro.endereco : ""}
                  inputMaskChange={(v) =>
                    setCadastro((e) => ({ ...e, endereco: v }))
                  }
                  placeholder="Endereço"
                />
              </div>
              <div style={{ flexBasis: "15%" }}>
                <label>Número:</label>
                <Input
                  mask="none"
                  value={cadastro.nr ? cadastro.nr : ""}
                  inputMaskChange={(v) => setCadastro((e) => ({ ...e, nr: v }))}
                  type="tel"
                  placeholder="Número"
                />
              </div>
            </div>
            <div>
              <label>Bairro:</label>
              <Input
                mask="none"
                value={cadastro.bairro ? cadastro.bairro : ""}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, bairro: v }))
                }
                placeholder="Bairro"
              />
            </div>

            <div>
              <label>Complemento:</label>
              <Input
                mask="none"
                value={cadastro.complemento ? cadastro.complemento : ""}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, complemento: v }))
                }
                placeholder="Complemento"
              />
            </div>

            <div className="flexRow">
              <div style={{ flexBasis: "60%" }}>
                <label>Cidade:</label>
                <Input
                  mask="none"
                  value={cadastro.cidade ? cadastro.cidade : ""}
                  inputMaskChange={(v) =>
                    setCadastro((e) => ({ ...e, cidade: v }))
                  }
                  placeholder="Cidade"
                />
              </div>
              <div style={{ flexBasis: "15%" }}>
                <label>Estado:</label>
                <Input
                  mask="none"
                  value={cadastro.estado ? cadastro.estado : ""}
                  inputMaskChange={(v) =>
                    setCadastro((e) => ({ ...e, estado: v }))
                  }
                  placeholder="Estado"
                />
              </div>
            </div>
          </div>

          <div className="painel">
            <div>
              <label>Senha</label>
              <Input
                mask="none"
                value={cadastro.senha ? cadastro.senha : ""}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, senha: v }))
                }
                placeholder="Senha"
                type="password"
              />
            </div>

            <div>
              <label>Redigite a Senha</label>
              <Input
                mask="none"
                value={redSenha}
                inputMaskChange={(v) => setRedSenha(v)}
                placeholder="Redigite a Senha"
                type="password"
              />
            </div>
          </div>

          <button onClick={() => handleCadastrar()}>
            <FontAwesomeIcon icon={faCheckDouble} />
            SALVAR CADASTRO
          </button>
        </>
      )}
    </div>
  );
}
