import React, { KeyboardEvent, useState } from "react";
import {
  faRightToBracket,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../hooks/useSiteContext";
import { api } from "../../services/api";
import "./style.css";

export function LoginCliente() {
  const navigate = useNavigate();
  const { cliente, logout, saveTokens, carrinho } = useSiteContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") handleLogin();
  };

  const handleLogin = async () => {
    if (usuario.length <= 3) return alert("Preencha o usuário para login");

    if (senha.length < 3) return alert("Preencha a senha para login");

    setLoading(true);
    setTxtLoading("Efetuando login...");

    await api
      .post("clientes/login", {
        usuario,
        senha,
      })
      .then((res) => {
        if (!res.data)
          throw new Error("Erro ao recuperar informações de login");

        const result = res.data;
        saveTokens(result.token, result.cliente);

        console.log(carrinho.length);

        if (carrinho.length === 0) {
          navigate("/painel/meus-cursos");
          return;
        }

        navigate("/pgto");
      })
      .catch((err) => {
        if (err.response?.data?.msg) alert(err.response.data.msg);
        else alert("Erro ao efetuar login. Tente novamente mais tarde");

        setLoading(false);
        setTxtLoading("");
      });
  };

  return (
    <div id="pgLoginCliente">
      {loading && <Load txtLoading={txtLoading} />}

      {!loading && cliente?.nome && (
        <div className="painel" style={{ width: "100%" }}>
          <div className="sessionLogin">
            Você é <b>{cliente.nome}</b>?
            <div className="sessionBtnContent">
              <button onClick={() => navigate("/pgto")}>SIM</button>
              <button
                onClick={() => logout()}
                style={{ background: "#ee4457" }}
              >
                NÃO
              </button>
            </div>
          </div>
        </div>
      )}

      {!loading && !cliente?.nome && (
        <>
          <div className="painel">
            <h3>Login</h3>

            <Input
              mask="none"
              value={usuario}
              inputMaskChange={(v) => setUsuario(v)}
              placeholder="E-mail"
              type="email"
              onKeyUp={handleKeyUp}
            />

            <Input
              mask="none"
              value={senha}
              inputMaskChange={(v) => setSenha(v)}
              placeholder="Senha"
              type="password"
              onKeyUp={handleKeyUp}
            />

            <button onClick={() => handleLogin()}>
              <FontAwesomeIcon icon={faRightToBracket} />
              LOGIN
            </button>
          </div>

          <div className="painel">
            <h3>Criar uma conta</h3>
            <p>Faça seu cadastro agora mesmo.</p>

            <button className="newCad" onClick={() => navigate("/cadCliente")}>
              <FontAwesomeIcon icon={faUserPlus} />
              CRIAR CADASTRO
            </button>
          </div>
        </>
      )}
    </div>
  );
}
