import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Input } from "../../components/Input";
import { Load } from "../../components/Load";
import { IEmpresa } from "../../global/types";
import { useAdmContext } from "../../hooks/useAdmContext";
import { api } from "../../services/api";

import "./style.css";

export function Empresa() {
  const { tokens, checkToken } = useAdmContext();

  const [loading, setLoading] = useState(false);
  const [txtLoading, setTxtLoading] = useState("");

  const [cadastro, setCadastro] = useState<IEmpresa>({} as IEmpresa);

  const loadCadastro = useCallback(async () => {
    setLoading(true);
    setTxtLoading("Carregando cadastro...");

    const canLoad = await checkToken();
    if (!canLoad) return;

    await api
      .get(`empresa`)
      .then((res) => {
        if (!res.data) return;

        const cad = res.data as IEmpresa;

        setCadastro({ ...cad });
      })
      .catch((err) => {
        if (err.response?.data?.msg) alert(err.response?.data?.msg);
        else
          alert(
            "Erro ao tentar recuperar dados da Empresa. Tente novamente mais tarde."
          );
      })
      .finally(() => {
        setLoading(false);
        setTxtLoading("");
      });
  }, []);

  const handleSalvar = async () => {
    setLoading(true);
    setTxtLoading("Salvando dados...");

    try {
      const canLoad = await checkToken();
      if (!canLoad) return;

      await api.post(`empresa`, { ...cadastro });

      alert("Dados salvos com sucesso!");
    } catch (err: any) {
      if (err.response?.data?.msg) alert(err.response.data.msg);
      else
        alert(
          "Erro ao salvar os dados da Empresa. Tente novamente mais tarde."
        );
    } finally {
      setLoading(false);
      setTxtLoading("");
    }
  };

  useEffect(() => {
    if (tokens?.token) loadCadastro();
  }, [tokens, loadCadastro]);

  return (
    <div id="pgEmpresa">
      {loading && <Load txtLoading={txtLoading} />}

      {!loading && (
        <>
          <div className="painel form">
            <p className="title">Dados da Empresa</p>

            <span style={{ width: "49%" }}>
              <label>Nome</label>
              <Input
                mask="none"
                value={cadastro.nome}
                inputMaskChange={(v) => setCadastro((e) => ({ ...e, nome: v }))}
              />
            </span>

            <span style={{ width: "49%" }}>
              <label>Endereço</label>
              <Input
                mask="none"
                value={cadastro.endereco}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, endereco: v }))
                }
              />
            </span>

            <span style={{ width: "49%" }}>
              <label>E-Mail</label>
              <Input
                mask="none"
                value={cadastro.email}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, email: v }))
                }
              />
            </span>

            <span style={{ width: "49%" }}>
              <label>Telefone/Celular</label>
              <Input
                mask="none"
                value={cadastro.telefone}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, telefone: v }))
                }
              />
            </span>

            <span style={{ width: "49%" }}>
              <label>CNPJ</label>
              <Input
                mask="none"
                value={cadastro.cnpj}
                inputMaskChange={(v) => setCadastro((e) => ({ ...e, cnpj: v }))}
              />
            </span>

            <span style={{ width: "49%" }}>
              <label>PagSeguro (Modo)</label>
              <select
                defaultValue={cadastro.pagseguro_env}
                onChange={(v) =>
                  setCadastro((old) => ({
                    ...old,
                    pagseguro_env: `${v.target.value}`,
                  }))
                }
              >
                <option value="sandbox">Modo de Teste</option>
                <option value="production">Modo de Venda</option>
              </select>
            </span>
            <span style={{ width: "49%" }}>
              <label>PagSeguro (Nome)</label>
              <Input
                mask="none"
                value={cadastro.pagseguro_name}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, pagseguro_name: v }))
                }
              />
            </span>
            <span style={{ width: "49%" }}>
              <label>PagSeguro (E-mail)</label>
              <Input
                mask="none"
                value={cadastro.pagseguro_email}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, pagseguro_email: v }))
                }
              />
            </span>
            <span style={{ width: "49%" }}>
              <label>PagSeguro (TOKEN)</label>
              <Input
                mask="none"
                value={cadastro.pagseguro_token}
                inputMaskChange={(v) =>
                  setCadastro((e) => ({ ...e, pagseguro_token: v }))
                }
              />
            </span>
          </div>

          <div className="btnPainel">
            <button
              className="btn-green"
              onClick={() => {
                handleSalvar();
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
              SALVAR
            </button>
          </div>
        </>
      )}
    </div>
  );
}
