import {
  faBarcode,
  faCashRegister,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { faCreditCard as faDebitCard } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Load } from "../../components/Load";
import { IClientes } from "../../global/types";
import { useSiteContext } from "../../hooks/useSiteContext";
import { api } from "../../services/api";
import { currencyFormatter } from "../../utils/currencyFormatter";

import "./style.css";
import { Input } from "../../components/Input";
import {
  handleErrorPagSeguro,
  handleInstallmentRate,
} from "../../utils/pagSeguro";
import {
  finalizeBoleto,
  finalizeCreditCard,
  finalizeDebitCard,
  IInstallments,
  IInstallmentsBrands,
  IPaymentPagSeguro,
} from "../../services/pagSeguroApi";

export function Pgto() {
  const navigate = useNavigate();

  const PagSeguroDirectPay = (window as any).PagSeguroDirectPayment;

  const { carrinho, checkToken, tokens, saveCarrinho } = useSiteContext();

  const [paymentPagSeguro, setPaymentPagSeguro] = useState<IPaymentPagSeguro>(
    {} as IPaymentPagSeguro
  );

  const [installmentsPagSeguro, setInstallmentsPagSeguro] = useState<
    IInstallments[]
  >([]);

  const [cliente, setCliente] = useState<IClientes>({} as IClientes);

  const [loading, setLoading] = useState<{
    isOverlay: boolean;
    isLoading: boolean;
    txtLoading: string;
  }>({
    isOverlay: true,
    isLoading: true,
    txtLoading: "Verificando login do cliente...",
  });

  const [total, setTotal] = useState(0);

  const [paymentMethod, setPaymentMethod] = useState<
    undefined | "BOLETO" | "CREDIT_CARD" | "DEBIT_CARD"
  >();

  const [cardNumber, setCardNumber] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [holderName, setHolderName] = useState("");
  const [brandCard, setBrandCard] = useState("");

  const verificaLogin = useCallback(async (token: string) => {
    try {
      const tokenChecking = await checkToken(token);

      if (!tokenChecking) throw new Error("Erro ao recuperar dados do login");

      setLoading({
        ...loading,
        txtLoading: "Recuperando dados do cliente...",
      });

      const idCliente: number = parseInt(token.split(".")[0]);
      const reqCliente = await api.get(`clientes/${idCliente}`);

      if (!reqCliente.data)
        throw new Error("Erro ao recuperar dados do Cliente");

      setCliente(reqCliente.data);

      setLoading({
        isLoading: false,
        isOverlay: false,
        txtLoading: "Recuperando dados do cliente...",
      });
    } catch (err: any) {
      if (err.response?.data?.msg) alert(err.response.data.msg);
      else alert("Erro ao recuperar dados do login");

      navigate("/lgnCliente");
    }
  }, []);

  async function handleFinalizePayment() {
    if (
      paymentMethod !== "BOLETO" &&
      (cardNumber.length < 16 ||
        expMonth.length !== 2 ||
        expYear.length < 4 ||
        securityCode.length < 3 ||
        holderName.length < 5)
    )
      return alert("O preenchimento de todos os campos é obrigatório");

    setLoading({
      isLoading: true,
      isOverlay: true,
      txtLoading: "Enviando dados de pagamento...",
    });

    console.log("asdfasd 123");
    await Promise.all(
      carrinho.map(async (item, i) => {
        setLoading({
          isLoading: true,
          isOverlay: true,
          txtLoading: `Verificando disponibilidade ${i + 1}/${
            carrinho.length
          }...`,
        });

        const reqQtdVendas = await api.get(
          `cursos/qtdVendas?id_curso=${item.curso.id}`
        );

        if (!reqQtdVendas.data)
          throw new Error(
            `Erro ao recuperar vendas do curso "${item.curso.nome}"`
          );

        const qtdVendas: number = reqQtdVendas.data;
        const qtdCompra: number = item.quantidade;
        const qtdDisponivel: number = item.curso.maximo - qtdVendas;

        if (qtdVendas >= item.curso.maximo || qtdCompra > qtdDisponivel)
          if (carrinho.length > 1)
            throw new Error(
              `Venda máxima atingida do curso "${item.curso.nome}". Remova o curso de seu carrinho para processar o pagamento dos demais cursos`
            );
          else
            throw new Error(
              `Venda máxima atingida do curso "${item.curso.nome}".`
            );
      })
    )
      .then(async (resp) => {
        setLoading({
          isLoading: true,
          isOverlay: true,
          txtLoading: "Processando dados de pagamento...",
        });
        await PagSeguroDirectPay.onSenderHashReady(async (resp: any) => {
          if (resp.status == "error") {
            console.log(resp.message);
            return false;
          }
          setPaymentPagSeguro({
            ...paymentPagSeguro,
            tokenHashPagSeguro: resp.senderHash,
            finalize: true,
          });
          console.log("hash gerado com sucesso");
        });
      })
      .catch((err) => {
        if (err.response?.data?.msg) alert(err.response.data.msg);
        else if (err.message) alert(err.message);
        else alert("Erro ao processar compra. Tente novamente mais tarde");

        setLoading({
          txtLoading: "",
          isLoading: false,
          isOverlay: false,
        });
      });
  }

  useEffect(() => {
    if (tokens?.token) verificaLogin(tokens.token);
  }, [tokens, verificaLogin]);

  useEffect(() => {
    let newTotal = 0;

    carrinho.forEach((item) => {
      newTotal += item.quantidade * parseFloat(`${item.curso.valor}`);
    });

    setTotal(newTotal);
  }, [carrinho]);

  useEffect(() => {
    (async () => {
      await api
        .post("/pagseguro/hash")
        .then(async (resp) => {
          await PagSeguroDirectPay.setSessionId((await resp.data).hash);
          await PagSeguroDirectPay.getSenderHash();
          await PagSeguroDirectPay.getPaymentMethods({
            amount: total,
            success: function (resp: any) {
              console.log(resp);
            },
          });
        })
        .catch((error) => {
          console.log(error.toString());
          alert(
            "Não foi possível gerar uma hash para seu pagamento\nEntre em contato com o administrador do site"
          );
        });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let cardBinNumber = cardNumber.replace(/\D/g, "") || "";

      if (cardBinNumber.length >= 16 && paymentMethod === "CREDIT_CARD")
        await PagSeguroDirectPay.getBrand({
          cardBin: cardBinNumber.substr(0, 6),
          success: ({ brand }: { brand: { [key: string]: string } }) => {
            if (typeof brand.name !== "undefined") {
              setBrandCard(brand.name);
              console.log("Bandeira gerada com sucesso.");
            }
          },
          error: (resp: any) => {
            console.log("Error Brand: %O", resp);
            setPaymentPagSeguro({
              ...paymentPagSeguro,
              finalize: false,
              quantity: 0,
            });
          },
        });
    })();
  }, [cardNumber]);

  useEffect(() => {
    (async () => {
      if (brandCard && paymentMethod === "CREDIT_CARD")
        await PagSeguroDirectPay.getInstallments({
          amount: total,
          brand: brandCard,
          success: async ({
            installments,
          }: {
            installments: IInstallmentsBrands;
          }) => {
            if (installments[brandCard]) {
              setInstallmentsPagSeguro(installments[brandCard]);
              console.log("parcelamento: %O", installments[brandCard]);
            }
          },
          error: (response: any) => {
            console.log("ERROR INSTALLMENTS: %O", response);
            setPaymentPagSeguro({
              ...paymentPagSeguro,
              finalize: false,
              quantity: 0,
            });
          },
        });
    })();
  }, [paymentMethod, brandCard]);

  useEffect(() => {
    (async () => {
      if (
        paymentMethod !== "CREDIT_CARD" ||
        !cardNumber ||
        !brandCard ||
        !securityCode ||
        !expMonth ||
        !expYear ||
        !paymentPagSeguro.quantity
      )
        return;

      await PagSeguroDirectPay.createCardToken({
        cardNumber: `${cardNumber}`,
        brand: `${brandCard}`,
        cvv: `${securityCode}`,
        expirationMonth: `${expMonth}`,
        expirationYear: `${expYear}`,
        success: async (resp: any) => {
          console.log("token card: %O", resp.card.token);
          setPaymentPagSeguro({
            ...paymentPagSeguro,
            cardHolderName: holderName,
            tokenPagSeguro: resp.card.token,
          });
        },
        error: (resp: any) => {
          console.log("error: %O", resp);
          let code = Object.keys(resp.errors)[0];
          if (resp.error) {
            alert(handleErrorPagSeguro(code));
          }
          setPaymentPagSeguro({
            ...paymentPagSeguro,
            quantity: 0,
          });
        },
      });
    })();
  }, [
    paymentMethod,
    cardNumber,
    brandCard,
    securityCode,
    expMonth,
    expYear,
    paymentPagSeguro.quantity,
  ]);

  useEffect(() => {
    (async () => {
      if (!paymentPagSeguro.finalize) return;

      setLoading({
        txtLoading: "Finalizando pagamento...",
        isLoading: true,
        isOverlay: true,
      });

      let items: any[] = [];
      for (const item of carrinho)
        items.push({
          id: item.curso.id,
          description: item.curso.nome,
          quantity: item.quantidade,
          amount: parseFloat(`${item.curso.valor}`) * item.quantidade,
          weight: 0,
          shippingCost: 0,
        });

      localStorage.removeItem("artCartShopping");

      if (paymentMethod === "BOLETO")
        await finalizeBoleto(paymentPagSeguro, cliente, items)
          .then(async (resp) => {
            const uri = await resp.data;
            navigate({
              pathname: `/detalhes/${uri.id_venda}/code/${uri.code}`,
            });
          })
          .catch((err) => {
            const {
              response: {
                data: { msg },
              },
            } = err;
            console.log("finalizeCreditCard: %O", msg.error);
            if (msg.error.code) {
              alert(handleErrorPagSeguro(`${msg.error.code}`));
            }
            setPaymentPagSeguro({
              ...paymentPagSeguro,
              quantity: 0,
              finalize: false,
            });
            setLoading({
              txtLoading: "",
              isLoading: false,
              isOverlay: false,
            });
          });

      if (paymentMethod === "DEBIT_CARD")
        await finalizeDebitCard(paymentPagSeguro, cliente, items)
          .then(async (resp) => {
            const uri = await resp.data;
            navigate({
              pathname: `/detalhes/${uri.id_venda}/code/${uri.code}`,
            });
          })
          .catch((err) => {
            const {
              response: {
                data: { msg },
              },
            } = err;
            console.log("finalizeCreditCard: %O", msg.error);
            if (msg.error.code) {
              alert(handleErrorPagSeguro(`${msg.error.code}`));
            }
            setPaymentPagSeguro({
              ...paymentPagSeguro,
              quantity: 0,
              finalize: false,
            });
            setLoading({
              txtLoading: "",
              isLoading: false,
              isOverlay: false,
            });
          });

      if (paymentMethod === "CREDIT_CARD")
        await finalizeCreditCard(paymentPagSeguro, cliente, items)
          .then(async (resp) => {
            const uri = await resp.data;
            navigate({
              pathname: `/detalhes/${uri.id_venda}/code/${uri.code}`,
            });
          })
          .catch((err) => {
            const {
              response: {
                data: { msg },
              },
            } = err;
            console.log("finalizeCreditCard: %O", msg.error);
            if (msg.error.length > 1) {
              alert(handleErrorPagSeguro(`${msg.error[0].code}`));
            }

            if (msg.error.length === 1) {
              alert(handleErrorPagSeguro(`${msg.error.code}`));
            }

            setPaymentPagSeguro({
              ...paymentPagSeguro,
              quantity: 0,
              finalize: false,
            });
            setLoading({
              txtLoading: "",
              isLoading: false,
              isOverlay: false,
            });
          });
    })();
  }, [paymentPagSeguro]);

  return (
    <div id="pgPgto">
      {loading.isOverlay && <div className="overlayPgto" />}

      {loading.isLoading && <Load txtLoading={loading.txtLoading} />}

      {!loading.isLoading && (
        <>
          <div className="painel carrinho">
            {carrinho.map((item, i) => (
              <div key={`c-${i}`}>
                <p className="qtd">{item.quantidade}x</p>
                <p className="nome">{item.curso.nome}x</p>
                <p className="valor">
                  {currencyFormatter(
                    parseFloat(`${item.curso.valor}`) * item.quantidade
                  )}
                </p>
              </div>
            ))}
            <h4
              style={{
                fontSize: 12,
                paddingTop: 15,
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              Sub Total: {currencyFormatter(total)}
            </h4>
            <h4 style={{ fontSize: 18 }}>
              Total + Taxas: {currencyFormatter(paymentPagSeguro.totalAmount)}
            </h4>
          </div>

          <div className="painel cliente">
            <p>
              <b>Cliente:</b> {cliente.nome}
            </p>
            <p>
              <b>CPF:</b> {cliente.cpf}
            </p>
            <p>
              <b>Data Nascimento:</b> {cliente.data_nascimento}
            </p>
            <p>
              <b>E-mail:</b> {cliente.email}
            </p>
          </div>

          <div className="paymentContent">
            <div className="painel methodPay">
              <button
                className={`${
                  paymentMethod && paymentMethod === "CREDIT_CARD"
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  setPaymentMethod("CREDIT_CARD");
                  setPaymentPagSeguro({
                    ...paymentPagSeguro,
                    totalAmount: total,
                  });
                }}
              >
                <FontAwesomeIcon icon={faCreditCard} />
                CARTÃO DE CRÉDITO
              </button>

              <button
                className={`${
                  paymentMethod && paymentMethod === "DEBIT_CARD"
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  setPaymentMethod("DEBIT_CARD");
                  setPaymentPagSeguro({
                    ...paymentPagSeguro,
                    totalAmount: total,
                  });
                }}
              >
                <FontAwesomeIcon icon={faDebitCard} />
                CARTÃO DE DÉBITO
              </button>

              <button
                className={`${
                  paymentMethod && paymentMethod === "BOLETO" ? "selected" : ""
                }`}
                onClick={() => {
                  setPaymentMethod("BOLETO");
                  setPaymentPagSeguro({
                    ...paymentPagSeguro,
                    totalAmount: total + 1,
                  });
                }}
              >
                <FontAwesomeIcon icon={faBarcode} />
                BOLETO
              </button>
            </div>

            <div className="painel formPay">
              {!paymentMethod && <h3>SELECIONE UMA FORMA DE PAGAMENTO</h3>}

              {paymentMethod && paymentMethod !== "BOLETO" && (
                <>
                  <label>Número do Cartão:</label>
                  <Input
                    mask="credit_card"
                    value={cardNumber}
                    inputMaskChange={(v) => setCardNumber(v)}
                    placeholder="Número do Cartão"
                    maxLength={19}
                    type="tel"
                  />

                  <label>Nome no Cartão:</label>
                  <Input
                    mask="none"
                    value={holderName}
                    inputMaskChange={(v) => setHolderName(v)}
                    placeholder="Nome do Cartão"
                  />

                  <label>Vencimento:</label>
                  <div className="flexRow">
                    <Input
                      mask="none"
                      value={expMonth}
                      inputMaskChange={(v) => setExpMonth(v)}
                      placeholder="Mês"
                      type="tel"
                      maxLength={2}
                    />
                    <Input
                      mask="none"
                      value={expYear}
                      inputMaskChange={(v) => setExpYear(v)}
                      placeholder="Ano"
                      type="tel"
                      maxLength={5}
                    />
                  </div>

                  <label>Código de Segurança:</label>
                  <div className="flexRow">
                    <Input
                      mask="none"
                      value={securityCode}
                      inputMaskChange={(v) => setSecurityCode(v)}
                      placeholder="Código de Segurança"
                    />
                  </div>

                  {paymentMethod === "CREDIT_CARD" && (
                    <>
                      <label>Forma de Parcelamento:</label>
                      <div className="flexRow">
                        <select
                          onChange={(e) =>
                            setPaymentPagSeguro({
                              ...paymentPagSeguro,
                              ...(installmentsPagSeguro?.[
                                parseInt(e.currentTarget.value)
                              ]
                                ? installmentsPagSeguro?.[
                                    parseInt(e.currentTarget.value)
                                  ]
                                : { quantity: 0 }),
                            })
                          }
                          disabled={!installmentsPagSeguro?.length}
                          style={{ width: 250 }}
                        >
                          {!installmentsPagSeguro?.length ? (
                            <option value="">Digite o número do cartão</option>
                          ) : (
                            <>
                              <option value="-1">
                                Selecione o parcelamento
                              </option>
                              {installmentsPagSeguro?.map(
                                (installments, index) => (
                                  <option value={index} key={`opt-${index}`}>
                                    {installments.quantity}x de{" "}
                                    {currencyFormatter(
                                      installments.installmentAmount
                                    )}{" "}
                                    (
                                    {currencyFormatter(
                                      installments.totalAmount
                                    )}{" "}
                                    {!installments.interestFree
                                      ? `taxa de ${handleInstallmentRate(
                                          installments.totalAmount,
                                          total
                                        )}%`
                                      : "sem juros"}
                                    )
                                  </option>
                                )
                              )}
                            </>
                          )}
                        </select>
                      </div>
                    </>
                  )}
                </>
              )}

              {paymentMethod && (
                <button onClick={() => handleFinalizePayment()}>
                  <FontAwesomeIcon icon={faCashRegister} />
                  ENVIAR PAGAMENTO
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
