import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSiteContext } from "../../hooks/useSiteContext";
import { detailsPaymentId } from "../../services/pagSeguroApi";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { convertDateToBr, convertToDate } from "../../utils/dateProvider";

import "./style.css";

export type DetailsCursosProps = {
  curso: string;
  quantidade: number;
  valor_total: number;
  data_inicio: string;
  hora_inicio: string;
};

export type ClienteCursosProps = {
  cpf: string;
  email: string;
  nome: string;
  data_nascimento: string;
  telefone: string;
};

export type EnderecoCursosProps = {
  endereco: string;
  nr: string | number;
  bairro: string;
  complemento: string;
  cidade: string;
  estado: string;
  cep: string;
};

export type TransacaoCursosProps = {
  code: string;
  forma_pgto: string;
  parcela: number;
  vl_pago: number;
  pgto_aprovado: string | null;
  status: {
    code: number;
    text: string;
    timestamp: string;
  }[];
};

export type DetailsProps = {
  id: number;
  cod_venda: string;
  created_at: string;
  excluir: number;
  cliente: ClienteCursosProps;
  endereco: EnderecoCursosProps;
  transacoes: TransacaoCursosProps[];
  cursos: DetailsCursosProps[];
  subTotal: number;
  total: number;
};

export function DetalhesPagamento() {
  const [loading, setLoding] = useState(true);
  const [details, setDetails] = useState<DetailsProps>({} as DetailsProps);
  const { id: idVenda, code: codeVenda } = useParams();

  const { tokens } = useSiteContext();

  useEffect(() => {
    (async () => {
      await detailsPaymentId({ idVenda: `${idVenda}`, token: tokens }).then(
        async (resp) => {
          setDetails(await resp.data);
          setLoding(false);
        }
      );
    })();
  }, [idVenda, tokens]);

  return (
    <div id="finallyPgto">
      {codeVenda && (
        <>
          <Link to="/painel/meus-cursos" className="btn">
            Voltar - Meus Cursos
          </Link>
          <h1
            style={{
              flexBasis: "100%",
            }}
          >
            Pagamento processado com sucesso
          </h1>
        </>
      )}

      <div
        className="painel details"
        style={{ flexBasis: "100%", lineHeight: 1.7 }}
      >
        Ogrigado por adquirir nossos cursos. <br />
        Seu processado com sucesso. <br />
        Você receberá novos e-mail de pagamento com a situação de status <br />
        <hr />
        <strong>COD.PGTO:</strong> {details.cod_venda}
        <br />
        <strong>DATA COMPRA:</strong> {details.created_at}
      </div>

      {!loading && (
        <>
          <div className="painel details">
            {details.cursos.map((item, i) => (
              <div key={`c-${i}`}>
                <p className="qtd">{item.quantidade}x</p>
                <p className="nome">
                  <strong>{item.curso}</strong>
                  <small style={{ display: "block" }}>
                    Data:{" "}
                    {`${convertDateToBr(
                      convertToDate(item.data_inicio)
                    )} às ${item.hora_inicio.substring(0, 5)}`}
                  </small>
                </p>
                <p className="valor">
                  {currencyFormatter(
                    parseFloat(`${item.valor_total}`) * item.quantidade
                  )}
                </p>
              </div>
            ))}
            <h4
              style={{
                fontSize: 12,
                paddingTop: 15,
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              Sub Total: {currencyFormatter(details.subTotal)}
            </h4>
            <h4 style={{ fontSize: 18 }}>
              Total + Taxas: {currencyFormatter(details.total)}
            </h4>
          </div>

          <div className="painel cliente">
            <p>
              <b>Cliente:</b> {details.cliente.nome}
            </p>
            <p>
              <b>CPF:</b> {details.cliente.cpf}
            </p>
            <p>
              <b>Data Nascimento:</b> {details.cliente.data_nascimento}
            </p>
            <p>
              <b>E-mail:</b> {details.cliente.email}
            </p>
          </div>

          <div className="painel details">
            <div key={`001-0`}>
              <p className="nome" style={{ fontWeight: "bold" }}>
                Forma de Pagamento
              </p>
              <p className="valor" style={{ fontWeight: "bold" }}>
                Total
              </p>
            </div>
            <hr style={{ flexBasis: "100%" }} key={`001-1`} />
            {details.transacoes.map((item, i) => (
              <Fragment key={`s-${i}`}>
                <div>
                  <p className="nome">{item.forma_pgto}</p>
                  <p className="valor" style={{ fontWeight: "bold" }}>
                    {currencyFormatter(
                      parseFloat(`${item.parcela}`) * item.vl_pago
                    )}
                  </p>
                </div>
                <br style={{ flexBasis: "100%" }} />
                <br style={{ flexBasis: "100%" }} />
                <div>
                  <p className="nome" style={{ fontWeight: "bold" }}>
                    Status de Pagamento
                  </p>
                  <p className="valor" style={{ fontWeight: "bold" }}></p>
                </div>
                <hr style={{ flexBasis: "100%" }} />
                {item.status.map((stts, i) => (
                  <div key={`stts-${i}`}>
                    <p
                      className="nome"
                      dangerouslySetInnerHTML={{ __html: stts.text }}
                    />
                    {/* <p className="nome">{stts}</p> */}
                    <p className="valor"></p>
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
