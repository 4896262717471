import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useSiteContext } from "../../hooks/useSiteContext";

import "./style.css";

export function PainelSite() {
  const { cliente, tokens, logout } = useSiteContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!tokens.token) navigate("/lgnCliente");
  }, []);

  console.log(!tokens.token);

  return (
    <div className="painel-2">
      <ul>
        <li>Olá {cliente?.nome}</li>
        <li>
          <Link to="/painel/meus-cursos">Meus Cursos</Link>
        </li>
        <li>
          <span onClick={logout}>Sair</span>
        </li>
      </ul>
      <div>
        <Outlet />
      </div>
    </div>
  );
}
