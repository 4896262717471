export function handleInstallmentRate(priceEnd: number, priceInitial: number) {
  const price = ((priceEnd - priceInitial) / priceInitial) * 100;
  return parseFloat(`${price}`).toFixed(2).replace(".", ",");
}

export function handleErrorPagSeguro(code: string) {
  switch (code) {
    case "10000":
      return "Digite o número do cartão.";

    case "10001":
      return "Número do cartão de crédito com comprimento inválido.";

    case "10002":
      return "Formato de data inválido.";

    case "30400":
      return "Data do cartão inválida.";

    case "10003":
      return "Campo de segurança inválido.";

    case "10004":
      return "CVV Obrigatório.";

    case "10006":
      return "Campo de segurança com comprimento inválido.";

    case "53020":
    case "53021":
      return "Verifique telefone inserido";

    case "53010":
    case "53011":
    case "53012":
      return "Verifique o e-mail inserido";

    case "53017":
      return "Verifique o CPF inserido";

    case "53018":
    case "53019":
      return "Verifique o DDD inserido";

    case "53013":
    case "53014":
    case "53015":
      return "Verifique o nome inserido";

    case "53029":
    case "53030":
      return "Verifique o bairro inserido";

    case "53022":
    case "53023":
      return "Verifique o CEP inserido";

    case "53024":
    case "53025":
      return "Verifique a rua inserido";

    case "53026":
    case "53027":
      return "Verifique o número inserido";

    case "53033":
    case "53034":
      return "Verifique o estado inserido";

    case "53031":
    case "53032":
      return "Verifique a cidade informada";

    case "10001":
      return "Verifique o número do cartão inserido";

    case "10002":
    case "30405":
      return "Verifique a data de validade do cartão inserido";

    case "10004":
      return "É obrigatorio informar o código de segurança, que se encontra no verso, do cartão";

    case "10006":
    case "10003":
    case "53037":
      return "Verifique o código de segurança do cartão informado";

    case "30404":
      return "Ocorreu um erro. Atualize a página e tente novamente!";

    case "53047":
      return "Verifique a data de nascimento do titular do cartão informada";

    case "53053":
    case "53054":
      return "Verifique o CEP inserido";

    case "53055":
    case "53056":
      return "Verifique a rua inserido";

    case "53042":
    case "53043":
    case "53044":
      return "Verifique o nome inserido";

    case "53057":
    case "53058":
      return "Verifique o número inserido";

    case "53062":
    case "53063":
      return "Verifique a cidade informada";

    case "53045":
    case "53046":
      return "Verifique o CPF inserido";

    case "53060":
    case "53061":
      return "Verifique o bairro inserido";

    case "53064":
    case "53065":
      return "Verifique o estado inserido";

    case "53051":
    case "53052":
      return "Verifique telefone inserido";

    case "53049":
    case "53050":
      return "Verifique o código de área informado";

    case "53122":
      return "Enquanto na sandbox do PagSeguro, o e-mail deve ter o domínio @sandbox.pagseguro.com.br (ex.: comprador@sandbox.pagseguro.com.br)";

    case "53038":
      return "Selecione a forma de pacelamento da compra.";
    default:
      return "Por favor, revise os dados do cartão digitados e tente novamente.";
  }
}
